import { Container } from "@mui/material"
import FlashMessage from "./FlashMessage"
const Body = ({ id, children }) => {
    return (
        <Container id={
            id !== undefined ? id : 'bodyContainer'
        }>
            <FlashMessage />
            {children}
        </Container>
    )
}

export default Body