import { Alert, Collapse } from "@mui/material"
import { useContext } from "react"
import { FlashContext } from "../contexts/FlashProvider"

const FlashMessage = () => {
    const { flashMessage, visible, hideFlash } = useContext(FlashContext)

    return (
        <Collapse in={visible}>
            <div>
                <Alert id='alert-flash-message' severity={flashMessage.type || 'info'}
                    onClose={hideFlash} data-visible={visible}>
                    {flashMessage.message}
                </Alert>
            </div>
        </Collapse>
    );
}

export default FlashMessage