import { useEffect, useRef, useState, Fragment } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";

import Body from "../components/Body";
import { useStyles } from "../contexts/StylesProvider";
import { useUser } from "../contexts/UserProvider";
import { useFlash } from "../contexts/FlashProvider";
import { validateEmail } from "../utils/validators";

const LoginPage = () => {

    const [mode, setMode] = useState('login');

    const [formErrors, setFormErrors] = useState({});
    const [usernameValue, setUsernameValue] = useState('')
    const [passwordValue, setPasswordValue] = useState('')

    const usernameField = useRef();
    const { login, resetPassword } = useUser();
    const flash = useFlash();
    const styles = useStyles();

    const { navigate } = useNavigate();
    const { location } = useLocation();

    useEffect(() => {
        usernameField.current.focus();
    },[])

    useEffect(() => {
        setFormErrors({});
    },[mode])

    const onSubmit = async (ev) => {
        ev.preventDefault();
        const errors = {};
        if (usernameValue === '') {
            errors.username = 'Username is required.';
        };
        if (validateEmail(usernameValue).valid===false) {
            errors.username = 'Invalid email address.';
        };
        if (mode==='login' && passwordValue === '') {
            errors.password = 'Password is required.';
        };   
        setFormErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }   

        if (mode==='login') {
            const result = await login(usernameValue, passwordValue);
            if (result === 'fail') {
                flash('invalid login','error',2);
            }
            else if (result === 'ok') {
                let next = '/';
                if (location.state && location.state.next) {
                    next = location.state.next;
                }
                navigate(next)
            };
        };
        if (mode==='reset') {
            const result = await resetPassword(usernameValue);
            if (result.ok===true) {
                flash('Check your email shortly for details on how to reset your password.','success');
            }
            else {
                flash('An error occurred. Find a counselor.','error');
            };
            setUsernameValue('');
            return;
        };
    };

    const handleUsernameChange = (ev) => {
        setUsernameValue(ev.target.value);
    };

    const handlePasswordChange = (ev) => {
        setPasswordValue(ev.target.value);
    };

    return (
        <Body>
            <Container>
                <Typography sx={styles.textPageTitle}>Welcome</Typography>
                {mode === 'login' && <Fragment>
                    <Typography variant="body2" sx={styles.textInstructions}>Please log in.</Typography>
                        <Box id='bodyContentBox' sx={styles.bodyContentBox}>
                            <form onSubmit={onSubmit}>
                            <TextField
                                id="field-username"
                                label="Email"
                                inputRef={usernameField}
                                value={usernameValue}
                                onChange={handleUsernameChange}
                                variant="outlined"
                                error={'username' in formErrors}
                                helperText={formErrors.username}
                                sx={styles.formField}
                                />
                            <TextField
                                id="field-password"
                                label="Password"
                                value={passwordValue}
                                onChange={handlePasswordChange}
                                variant="outlined"
                                type="password"
                                error={'password' in formErrors}
                                helperText={formErrors.password}
                                sx={styles.formField}
                            />
                            <div sx={styles.flexBreak} />
                            <Button id='button-login' variant="contained" type="submit" sx={{ mt: 2, ml: 1 }}>Log In</Button>
                            <div sx={styles.flexBreak} />
                            <Button sx={{ mt: 1 }} onClick={() => setMode('reset')}>I forgot my password</Button>
                        </form>
                    </Box>
                </Fragment>}
                {mode === 'reset' && <Fragment>
                        <Typography variant="body2" sx={styles.textInstructions}>Enter your email address to reset your password. You will receive an email with further instructions.</Typography>
                        <Typography variant="body2" sx={styles.textInstructions}>(Campers who repeatedly lose their passwords may have to sing a solo at lunch.)</Typography>
                        <form onSubmit={onSubmit}>
                            <TextField
                                    id="username-field"
                                    label="Email"
                                    inputRef={usernameField}
                                    value={usernameValue}
                                    onChange={handleUsernameChange}
                                    variant="outlined"
                                    error={'username' in formErrors}
                                    helperText={formErrors.username}
                                    sx={styles.formField}
                            />
                            <div sx={styles.flexBreak} />
                            <Button variant="contained" type="submit" sx={{ mt: 2, ml: 1 }}>Reset Password</Button>
                            <Button variant="outlined" sx={{ mt: 2, ml: 3 }} onClick={() => setMode('login')}>Back to Login</Button>
                        </form>
                </Fragment>}
            </Container>
        </Body>
    )
}

export default LoginPage